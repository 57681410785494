body {
    font-family: Helvetica, Helvetica Neue, Arial, sans-serif;
    color: #444444;
    background-color: #f5f5f5;
}

main {
    background-color: #ffffff;
    padding: .5rem 2rem;
    max-width: 40rem;
    margin: 2rem auto;
}
